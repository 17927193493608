import React from 'react';

const FAQ = () => {
  return (
    <div className="col order-5 my-5 my-sm-4">
      <div className="card border mt-4 h-100">
        <div className="card-body small">
          <h4 className="text-center">FAQ</h4>
          <div className={'pt-4'}>
            <b>Q: Will I receive a tracking number?</b>
            <p>
              A: Absolutely! Once your order is fulfilled, you will receive a notification email
              including a tracking link.
            </p>

            <b>Q: My order was damaged.</b>
            <p>
              A: We’re sorry about that! As such, per our current policy, we will issue you a refund
              for your order in full. If you would still like the product/s, you may place a new
              order on the site! Please email us for further assistance.
            </p>

            <b>Q: When will my order ship?</b>
            <p>
              A: Most orders, unless it’s a pre-order, are processed within 1 to 5 business days and
              are fulfilled by our licensed retail partners.
            </p>

            <span className={'small text-muted'}>
              **Please note, there may be delays due to holidays and inclement weather.**
            </span>
            <br />
            <span className={'small text-muted'}>We look forward to you receiving your order!</span>
            {/* For bulk order default link will be : https://help.getbarcart.com/collection/972-barcart-for-consumers */}
            <div className={'text-center mt-4'}>
              <a
                href={'https://help.getbarcart.com/collection/972-barcart-for-consumers'}
                className={'text-dark text-decoration-none'}
              >
                <i className={'bi bi-question-circle'} />
                &nbsp; Click here for more questions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
