const pendingStatusConstant = [
  "ACTION_REQUIRED",
  "REJECTED",
  "PRICE_TOO_LOW",
  "OOS_NA_WITH_DISTRIBUTOR",
  "OUT_OF_COVERAGE_AREA",
  "ORDERED_PRODUCT",
  "NEED_DISTRIBUTOR_INFO",
  "SPLIT_REQUIRED",
  "SPLIT REQUIRED",
  "ON_HOLD",
  "ESCALATE",
  "PRE_CONFIRMED"
];

export default pendingStatusConstant;
